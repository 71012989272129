/**
 * calculate card width in 16 columns grid, based on
 * specification provided for Grid- Dynamic component on zeroheight:
 * https://dr-oetker.zeroheight.com/09c9553b6/v/16651/p/50c771-grid---dynamic/b/318123
 * @param length total amount of cards in the grid
 * @param index current card position in the grid
 * @returns card width based on 16 columns grid
 */
export const getGridCardWidth = (length: number, index: number): number => {
  switch (length) {
    case 1:
      return 16;
    case 2:
    case 3:
      return index === 2 ? 16 : 8;
    case 4:
      return 8;
    case 5:
      return index === 0 || index === 1 ? 4 : 8;
    case 6:
      return index === 2 || index === 3 ? 8 : 4;
    default:
      return index === 6 ? 8 : 4;
  }
};
