/* eslint-disable @typescript-eslint/indent */
import type { RecipeResult } from 'types/Recipes';
import { transformAllergens } from './Allergens/AllergensTransformer';
import { transformDietTypes } from './DietTypes/DietTypesTransformer';
import { transformKeywords } from './Keywords/KeywordsTransformer';
import { transformMenuTypes } from './MenuTypes/MenuTypesTransformer';
import { transformOccasion } from './Occasion/OccasionTransformer';
import { transformSeasons } from './Seasons/SeasonsTransformer';

// Add more transformation for more components and as we add more recipe information to Algolia
const transformAlgoliaRecipes = (recipes: any[]): RecipeResult[] => {
  const transformedRecipes =
    recipes &&
    recipes.map(
      (recipe) =>
        ({
          Id: recipe.id,
          Slug: recipe.slug || null,
          Difficulty: recipe.difficulty,
          Title: recipe.title,
          Category: {
            Id: recipe.category_id,
            Title: recipe.category,
          },
          Keywords: recipe.keywords?.map((keyword: string) =>
            transformKeywords(keyword, recipe.id),
          ),
          ShortDescription: recipe?.description ?? '',
          MenuTypes: recipe.menu_types?.map((menuType: string) =>
            transformMenuTypes(menuType, recipe.id),
          ),
          Images: [
            {
              Title: recipe.title,
              Formats: [{ Url: recipe.images }],
            },
          ],
          Image: recipe.images ? recipe.images[1] || recipe.images[0] : [],
          BakingTimeInMinutes: recipe.baking_time || null,
          PreparationTimeInMinutes: recipe.preparation_time || null,
          TotalTimeInMinutes: recipe.total_time || null,
          Occasions: recipe.occasions?.map(transformOccasion),
          Seasons: recipe.seasons?.map(transformSeasons),
          Allergens: recipe.allergens?.map(transformAllergens),
          DietTypes: recipe.diet_types?.map(transformDietTypes),
          Priority: recipe.priority,
          Rating: recipe?.rating?.average_rating_score.toFixed(1) || null,
          Price:
            recipe.price_max !== recipe.price_min
              ? {
                  PriceSpanFrom: recipe.price_min,
                  PriceSpanTo: recipe.price_max,
                  Currency: recipe.currency,
                }
              : null,
        }) as any,
    );
  return transformedRecipes;
};

export default transformAlgoliaRecipes;
